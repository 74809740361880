.notifications-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.loading-message,
.error-message {
    text-align: center;
    margin: 10px 0;
}

.notifications-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.notification.read {
    background: #e0e0e0;
}

.notification-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.mark-read-button,
.delete-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.mark-read-button {
    background-color: #4caf50;
    color: white;
}

.delete-button {
    background-color: #f44336;
    color: white;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #2196f3;
    color: white;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
}
