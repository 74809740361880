@tailwind base;
@tailwind components;
@tailwind utilities;

  .hoverEffect:hover img {
    opacity: 0.7; 
    transition: all .3s ease-in-out;
  }
  
  .absolute-overlay{
    transition: all .3s ease-in-out;
  }

  .hover-zoom {
    overflow: hidden;
  }
  
  .hover-zoom img {
    transition: transform 0.3s ease;
  }
  
  .hover-zoom:hover img {
    transform: scale(1.1);
  }
  .error {
    color: #d9534f; 
    font-size: 0.875rem; 
    margin-top: 0.25rem; 
  }
  
  @layer  {
    .dashboard-link {
      @apply flex justify-start items-center gap-2 py-3.5 px-2 cursor-pointer hover:bg-gray-100;
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px);
  }
  
  .update-btn {
    background-color: green;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: red;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  